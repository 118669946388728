import React, { useState, useEffect } from 'react';
import StatsCard from '../../components/statsCard';
import "./dashboard.scss";
import axios from 'axios';
import { toast } from 'react-toastify';

function Dashboard (){
  const [ stats, setStats ] = useState({});
  
  useEffect(()=>{
    getStats();
  },[]);

  const getStats = async()=>{
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/getCounts`, {headers});

      const data = response.data;

      if (data.status) {
        setStats(data.data);
      }
    } catch (error) {
      console.error('getTraining error', error.response);
      toast.error(error.response.data.message);
    }
  }

  return (
    <div className="dashboard">
      <StatsCard title="Total Signups" number={stats.totalSignups} />
      <StatsCard title="Total Verified" number={stats.totalVerified} />
      <StatsCard title="Managers" number={stats.totalManagers} />
      <StatsCard title="Cashiers" number={stats.totalCashiers} />
      <StatsCard title="Ongoing Offers" number={stats.ongoingOffers} />
      <StatsCard title="Total Training Videos" number={stats.totalTraining} />
      <StatsCard title="Trainings for Mangers" number={stats.trainingForManagers} />
      <StatsCard title="Trainings for Cashiers" number={stats.trainingForCashier} />
    </div>
  );
};

export default Dashboard;
