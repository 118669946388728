import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import AddTrainingVideo from '../../components/addTrainingVideo';
import CommonTable from '../../components/commonTable';
import EditTrainingVideoModal from '../../components/editTrainingVideo';
import moment from 'moment';
import { USER_TYPE } from '../../common/constants';
import { truncateText } from '../../common/common';
import EmployeeWatchListModal from '../../components/viewTrainingCompletedList';

const TrainingVideos = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [ training, setTraining ] = useState([]);
  const [ editTrainingData, seteditTrainingData] = useState({});
  const [ isEditModalOpen, setEditModalOpen ] = useState(false);
  const [ isCompletedModalOpen, setIsCompletedModalOpen ] = useState(false);
  const [ trainingViewData, setTrainingViewData ] = useState({});

  useEffect(()=>{
    getTraining();
  }, []);

  const getTraining = async()=>{
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/getalltrainingvideos`, {headers});

      const data = response.data;

      if (data.status) {
        setTraining(data.data);
      }
    } catch (error) {
      console.error('getTraining error', error.response);
      toast.error(error.response.data.message);
    }
  }

  const columns = [
    {
      Header: "Created At",
      Cell: (row) => <div>{moment(row.row.original.createdAt).format('MM-DD-YYYY HH:mm')}</div>,
    },
    {
      Header: "Title",
      accessor: 'title'
    },
    {
      Header: "Description",
      Cell: (row)=> <div>{truncateText(row.row.original.description, 25)}</div>
    },
    {
      Header: "Training For",
      Cell: (row)=> <div>{row.row.original.trainingFor &&  row.row.original.trainingFor.length ? row.row.original.trainingFor.map( t => USER_TYPE[t] ).join()  : "-" }</div>
    },
    {
      Header: "Progress",
      Cell: (row)=><div> {row.row.original.trainingCompleted}  / {row.row.original.trainingAssigned}</div>
    },
    {
      Header: "Pending",
      accessor: 'trainingPending'
    },
    {
      Header: "Active",
      Cell: (row)=> <div>{row.row.original.isActive ? "Yes" : "No" }</div>
    },
    {
      Header: "Action",
      Cell: (row) => 
      <div>
        <button style={{ width: 60 }}  onClick={() => handleEdit(row.row.original) }>Edit</button>
        <button  style={{ margin: 8, width: 90 }} onClick={()=> handleView(row.row.original) }>Completed By</button>
      </div>
    }
  ];

  const handleView = (row ={})=>{
    setTrainingViewData(row);
    setIsCompletedModalOpen(true);
  }

  const handleEdit = (row = {} )=>{
    seteditTrainingData(row);
    setEditModalOpen(true);
  }

  return (
    <div className="app">
      <>
      <button onClick={() => setModalOpen(true)}>Add Training Videos</button>
      {
        training && training.length ?
         <CommonTable columns={columns} data={training}/>
         : null
      }
      </>
      <AddTrainingVideo isOpen={isModalOpen} onClose={() => setModalOpen(false)}  getTraining={getTraining} />
      {
        isEditModalOpen && 
         <EditTrainingVideoModal training={editTrainingData} isOpen={isEditModalOpen} onClose={()=>{ setEditModalOpen(false); seteditTrainingData({}); }}  getTraining={getTraining} />
      }
      {
        isCompletedModalOpen &&
        <EmployeeWatchListModal isOpen={isCompletedModalOpen} onClose={ ()=> setIsCompletedModalOpen(false)} trainingId={trainingViewData._id}  title={trainingViewData.title} />
      }
    </div>
  );
};

export default TrainingVideos;
