import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../SideBar";
import classes from "./layout.module.scss";

const PanelLayout = ({}) => {
  return (
    <div>
    <main className={classes.layout}>
      <Sidebar />
      <div className={classes.outletWrapper}>
        <Outlet />
      </div>
    </main>
    <footer>Created By Family Farms</footer>
    </div>
  );
};

export default PanelLayout;
