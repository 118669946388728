import React, { useState } from 'react';
import './login.scss';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function LoginScreen() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle the login logic here
    console.log('Login attempt with:', { email, password });

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/login`, {
        email,
        password,
      });

      const data = response.data;

      if (data.status) {
        localStorage.setItem('username', data.data.firstName);
        localStorage.setItem('token', data.data.adminToken);
        localStorage.setItem('email', email);
        localStorage.setItem('userId', data.data._id);
        localStorage.setItem('userType', data.data.userType);
        navigate('/');
      }
    } catch (error) {
      console.error('There was an error!', error.response);
      toast.error(error.response.data.message);
    }

  };
  
  return (
    <div className="login-screen">
      <div className="login-container">
        <h2 className="login-title">Welcome to Family Farms!</h2>
        <p className="login-instruction">Please sign-in to your account</p>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={handleEmailChange}
              placeholder="Email"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              required
            />
          </div>
          <button type="submit" className="btn-login">LOGIN</button>
          
        </form>
      </div>
    </div>
  );
}

export default LoginScreen;
