import React, { useEffect, useState } from 'react';
import './editOffer.scss';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ONE_MB } from '../../common/constants';

const EditOfferModal = ({ isOpen, onClose, offer, getOffers }) => {
  const [title, setTitle] = useState(offer.title);
  const [subtitle, setSubtitle] = useState(offer.subtitle);
  const [imageUrl, setImageUrl] = useState(offer.imageUrl);
  const [validTill, setValidTill] = useState(offer.validTill);
  const [isActive, setIsActive] = useState(offer.isActive);
  const [priority, setPriority] = useState(offer.priority);
  const [attachment, setAttachment] = useState('');
  const [ regularPrice, setRegularPrice ] = useState(offer.regularPrice);
  const [ discountPrice, setDiscountPrice ] = useState(offer.discountedPrice);

  useEffect(()=>{

  },[]);

  const uploadFile = (event)=>{
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > ONE_MB) {
        toast.error("File with maximum size of 1MB is allowed");
        return false;
      }
      setAttachment(event.target.files[0]);
    }
  }

  const handleSubmit = async(e) => {
    // Process the updated offer data here
    try {
      e.preventDefault();

      const valid = isValid();
      if(!valid) return;

      let formData = new FormData();
      const headers = {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
      formData.append("title", title);
      formData.append("subtitle", subtitle);
      formData.append('file', attachment);
      formData.append('priority', priority);
      formData.append('isActive', isActive);
      formData.append('offerId', offer._id);

      if(validTill){
        formData.append('validTill', validTill);
      }

      if(regularPrice){
        formData.append('regularPrice', regularPrice);
      }
      if(discountPrice){ 
        formData.append('discountedPrice', discountPrice);
      }


      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/offers/editoffer`,formData, {headers});

      toast.success("Offer updated");
      onClose();
      getOffers();
    } catch (error) {
      console.error('get error', error.response);
      toast.error(error.response.data.message);
    }
  };

  const isValid = () =>{
    if(!title.trim()){
      toast.error("Title required");
      return false;
    }
    if(!subtitle.trim()){
      toast.error('Subtitle required');
      return false;
    }
    if(!priority){
      toast.error('Priority required');
      return false;
    }
    if(regularPrice && isNaN(regularPrice)){
      toast.error('Regular price should be a number');
      return false;
    }
    if(discountPrice && isNaN(discountPrice)){
      toast.error('Discount price should be a number');
      return false;
    }
    // check if discount price is less than regular price
    if(discountPrice && regularPrice && parseFloat(discountPrice) > parseFloat(regularPrice)){
      toast.error('Discount price should be less than regular price');
      return false;
    }
    return true;
  }


  if (!isOpen) return null;

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="edit-offer-modal-overlay" onClick={handleOutsideClick}>
      <div className="edit-offer-modal">
        <button className="modal-close-btn" onClick={onClose}>&times;</button>
        <h2>Edit Offer</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Title:
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
          </label>
          <label>
            Subtitle:
            <textarea value={subtitle} onChange={(e) => setSubtitle(e.target.value)} />
          </label>
          <label>
             Image<span style={{color: 'red' }}></span>
            <input type="file" id="offer" name="file" accept="image/*"
              onChange={(e) => uploadFile(e)}  />
          </label>
          <img src={imageUrl} width={100} height={100} alt="Offer Preview" className="offer-image-preview"/>
          <label>
            Regular Price
            <input type="number" value={regularPrice} step="0.01" onChange={(e) => setRegularPrice(e.target.value)} />
          </label>
          <label>
            Discounted Price
            <input type="number" value={discountPrice} step="0.01" onChange={(e) => setDiscountPrice(e.target.value)} />
          </label>
          <label>
            Valid Till:
            <input type="date" value={validTill} onChange={(e) => setValidTill(e.target.value)} />
          </label>
          <label>
            Priority<span style={{color: 'red' }}>*</span>
            <select value={priority} onChange={(e) => setPriority(e.target.value)}>
              <option value="1">1(high priority)</option>
              <option value="2">2</option>
              <option value="3">3(low priority)</option>
            </select>
          </label>
          <label>
            Active:
            <input type="checkbox" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
          </label>
          <button type="submit">Update Offer</button>
        </form>
      </div>
    </div>
  );
};

export default EditOfferModal;
