import React, { useState, useEffect } from 'react';
import './viewTrainingCompletedList.scss';
import { toast } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

const ViewTrainingCompletedList = ({ isOpen, onClose, trainingId, title }) => {
  const [ employees, setEmployees ] = useState([]);

  useEffect(()=>{
    getEmpWatchList();
  }, []);

  const getEmpWatchList = async()=>{
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/getTrainingCompleted`, {trainingId }, {headers});
      const data = response.data;
      if (data.status) {
        setEmployees(data.data);
      }
    } catch (error) {
      console.error('getEmpWatchList error', error.response);
      toast.error(error.response.data.message);
    }
  }

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>{title}</h2>
        <table className="employee-table">
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Watched At</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee, index) => (
              <tr key={index}>
                <td>{!employee.userId ? "N/A" : "" } {employee?.userId?.firstName} {employee?.userId?.lastName}</td>
                <td>{moment(employee.createdAt).format('MM-DD-YYYY HH:mm')}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={onClose} className="close-button">Close</button>
      </div>
    </div>
  );
};

export default ViewTrainingCompletedList;
