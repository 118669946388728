const { MONTHS } = require('./constants');

export const getDateInStrFormat = (date) =>{
  let d = new Date(date);
  let dat = d.getDate();
  let mon = d.getMonth();
  let year = d.getFullYear();

  return `${dat} ${MONTHS[mon]} ${year}`;
}

export const truncateText = (text, wordLimit) => {
  const wordArray = text.split(' ');
  if (wordArray.length > wordLimit) {
    return wordArray.slice(0, wordLimit).join(' ') + '...';
  }
  return text;
};