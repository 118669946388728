import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import  { USER_TYPE, USER_TYP_NAME_TO_ID } from '../../common/constants';
import {getDateInStrFormat } from '../../common/common';
import './editUserDetails.scss';

const EditUserDetails = ({ isOpen, onClose, user, getAllUsers}) => {
  const [userType, setUserType] = useState(user.userType);
  const [isActive, setIsActive] = useState(user.isActive);

  const USER_TYPE_ARR = [
    { id: USER_TYP_NAME_TO_ID.CUSTOMER,  name: USER_TYPE[USER_TYP_NAME_TO_ID.CUSTOMER] },
    { id: USER_TYP_NAME_TO_ID.MANAGER, name: USER_TYPE[USER_TYP_NAME_TO_ID.MANAGER] },
    { id: USER_TYP_NAME_TO_ID.CASHIER, name: USER_TYPE[USER_TYP_NAME_TO_ID.CASHIER]},
    { id: USER_TYP_NAME_TO_ID.ADMIN, name:USER_TYPE[USER_TYP_NAME_TO_ID.ADMIN] }
  ];

  const handleSubmit = async (e) => {
    try{
      e.preventDefault();
      let obj = {
        id: user._id,
        usertype: userType,
        isActive: isActive
      };
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/updateuser`, obj, {headers});
      toast.success("User updated");
      onClose();
      getAllUsers();
    }catch(err){
      toast.error(err.response.data.message);
    }
   
  };

  if (!isOpen) return null;

  return (
    <div className="user-info-modal-overlay" onClick={(e) => e.target === e.currentTarget && onClose()}>
      <div className="user-info-modal" onClick={(e) => e.stopPropagation()}>
        <button className="close-btn" onClick={onClose}>&times;</button>
        <h2>User Details</h2>
        <form onSubmit={handleSubmit}>
          <label>Name: <span>{user.firstName} {user.lastName} </span></label>
          <label>Email: <span>{user.email}</span></label>
          <label>Mobile: <span>{user.mobile || 'N/A'}</span></label>
          <div className="side-by-side">
            <label>Is Verified: <span>{user.isVerified ? 'Yes' : 'No'}</span></label>
            <label>Verified At: <span>{user.verifiedAt ? getDateInStrFormat(user.verifiedAt) : 'N/A'}</span></label>
            <label>Created At: <span>{user.createdAt ? getDateInStrFormat(user.createdAt) : 'N/A'}</span></label>
          </div>
          <div className="side-by-side">
            <label>City: <span>{user?.cityId?.name}</span></label>
            <label>State: <span>{user?.stateId?.name}</span></label>
          </div>
          <label>Member ID: <span>{user.memId}</span></label>
          <label>Birthdate: <span>{user.birth_month} / {user.birth_date} / {user.birth_year}</span></label>
          <div className="side-by-side">
          <label>Address 1: <span>{user.address1 || 'N/A'}</span></label>
          <label>Address 2: <span>{user.address2 || 'N/A'}</span></label>
          </div>
          <label>Zip Code: <span>{user.zipCode}</span></label>
          <div className="side-by-side">
          <label>
            User Type:
            <select value={userType} onChange={(e) => setUserType(e.target.value)}>
              {
                USER_TYPE_ARR.map((u)=>(
                  <option key={u.id} value={u.id}>{u.name}</option>
                ))
              }
            </select>
          </label>
          <label>
            Active:
            <input type="checkbox" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
          </label>
          </div>
          <button type="submit">Update User</button>
        </form>
      </div>
    </div>
  );
};

export default EditUserDetails;
