import React from 'react';
import './statsCard.scss'; // Make sure to create a corresponding SASS file

const StatsCard = ({ title, number }) => {
  return (
    <div className="stats-card">
      <h2 className="card-title">{title}</h2>
      <p className="card-number">{number}</p>
    </div>
  );
};

export default StatsCard;
