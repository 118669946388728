import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ONE_MB } from '../../common/constants';
import './addOffer.scss';

const AddOfferModal = ({ isOpen, onClose, getOffers }) => {
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [validTill, setValidTill] = useState('');
  const [priority, setPriority] = useState('1'); // Default priority
  const [attachment, setAttachment] = useState('');
  const [ regularPrice, setRegularPrice ] = useState(0);
  const [ discountPrice, setDiscountPrice ] = useState(0);

  const resetModal = () => {
    setTitle('');
    setSubtitle('');
    setValidTill('');
    setPriority('1');
    setRegularPrice(0);
    setDiscountPrice(0);
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const addOffer = async (e) => {
    try {
      const valid = isValid();
      if(!valid) return;

      e.preventDefault();
      let formData = new FormData();
      const headers = {
        "Content-Type": "multipart/form-data",
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
      formData.append("title", title);
      formData.append("subtitle", subtitle);
      formData.append('file', attachment);
      formData.append('priority', priority);
      if(validTill){
        formData.append('validTill', validTill);
      }
      if(regularPrice){
        formData.append('regularPrice', regularPrice);
      }
      if(discountPrice){ 
        formData.append('discountedPrice', discountPrice);
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/offers/addoffer`,formData, {headers});

      const data = response.data;

      if (data.status) {
        closeModal(); // Close modal after submit
        getOffers();
      }
    } catch (error) {
      console.error('add offer error', error.response);
      toast.error(error.response.data.message);
    }
  };

  const isValid = () =>{
    if(!title.trim()){
      toast.error("Title required");
      return false;
    }
    if(!subtitle.trim()){
      toast.error('Subtitle required');
      return false;
    }
    if(!attachment){
      toast.error('Image required');
      return false;
    }
    if(!priority){
      toast.error('Priority required');
      return false;
    }
    if(regularPrice && isNaN(regularPrice)){
      toast.error('Regular price should be a number');
      return false;
    }
    if(discountPrice && isNaN(discountPrice)){
      toast.error('Discount price should be a number');
      return false;
    }
    if(discountPrice && regularPrice && parseFloat(discountPrice) > parseFloat(regularPrice)){
      toast.error('Discount price should be less than regular price');
      return false;
    }
    return true;
  }

  if (!isOpen) return null;

  const closeModal = ()=>{
    resetModal();
    onClose();
  }

  const uploadFile = (event)=>{
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > ONE_MB) {
        toast.error("File with maximum size of 1MB is allowed");
        return false;
      }
      setAttachment(event.target.files[0]);
    }
  }

  return (
    <div className="add-offer-modal-overlay" onClick={handleOutsideClick}>
      <div className="add-offer-modal">
        <button className="modal-close-btn" onClick={closeModal}>&times;</button>
        <h2>Add New Offer</h2>
        <form onSubmit={addOffer}>
          <label>
            Title<span style={{color: 'red' }}>*</span>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
          </label>
          <label>
            Subtitle<span style={{color: 'red' }}>*</span>
            <textarea
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              rows="3"
              required
            ></textarea>
          </label>
          <label>
             Image<span style={{color: 'red' }}>*</span>
            <input type="file" className="form-control" id="offer" name="file" accept="image/*"
              onChange={(e) => uploadFile(e)}  required  />
          </label>
          <label>
            Priority<span style={{color: 'red' }}>*</span>
            <select value={priority} onChange={(e) => setPriority(e.target.value)}>
              <option value="1">1(high priority)</option>
              <option value="2">2</option>
              <option value="3">3(low priority)</option>
            </select>
          </label>
          <label>
            Valid Till
            <input type="date" value={validTill} onChange={(e) => setValidTill(e.target.value)} />
          </label>
          <label>
            Regular Price
            <input type="number" step="0.1" value={regularPrice} onChange={(e) => setRegularPrice(e.target.value)} />
          </label>
          <label>
            Discounted Price
            <input type="number" step="0.1" value={discountPrice} onChange={(e) => setDiscountPrice(e.target.value)} />
          </label>
          <button type="submit">Add Offer</button>
        </form>
      </div>
    </div>
  );
};

export default AddOfferModal;
