import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import AddOfferModal from '../../components/addOffer';
import CommonTable from '../../components/commonTable';
import './offers.scss';
import EditOfferModal from '../../components/editOffer';
import moment from 'moment';

const Offers = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [ offers, setOffers ] = useState([]);
  const [ editOfferData, setEditOfferData] = useState({});
  const [ isEditModalOpen, setEditModalOpen ] = useState(false);

  useEffect(()=>{
    getOffers();
  }, []);

  const getOffers = async()=>{
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/offers/getalloffers`, {headers});

      const data = response.data;
      if (data.status) {
        setOffers(data.data);
      }
    } catch (error) {
      console.error('get error', error);
      toast.error(error.response.data.message);
    }
  }

  const columns = [
    {
      Header: "Created At",
      Cell: (row) => <div>{moment(row.row.original.createdAt).format('MM-DD-YYYY HH:mm')}</div>,
    },
    {
      Header: "Title",
      accessor: 'title'
    },
    {
      Header: "Subtitle",
      accessor: 'subtitle'
    },
    {
      Header: "Valid Till",
      Cell: (row) => <div>{ row.row.original.validTill ? moment(row.row.original.validTill).format('MM-DD-YYYY') : "-"}</div>,
    },
    {
      Header: "Priority",
      accessor: 'priority'
    },
    {
      Header: "Active",
      Cell: (row)=> <div>{row.row.original.isActive ? "Yes" : "No" }</div>
    },
    {
      Header: "Action",
      Cell: (row) => 
      <div>
          {
          <button onClick={() => handleEdit(row.row.original)}>Edit</button>
          }
      </div>
    }
  ];

  const handleEdit = (row = {} )=>{
    // console.log(row);
    setEditOfferData(row);
    setEditModalOpen(true);
  }


  return (
    <div className="app">
      <>
      <button onClick={() => setModalOpen(true)}>Add Offer</button>
      {
        offers && offers.length ?
         <CommonTable columns={columns} data={offers}/>
         : null
      }
      </>
      <AddOfferModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} getOffers={getOffers} />
      {
        isEditModalOpen && 
         <EditOfferModal offer={editOfferData} isOpen={isEditModalOpen} onClose={()=>{ setEditModalOpen(false); setEditOfferData({}); }} getOffers={getOffers} />
      }
    </div>
  );
};

export default Offers;
