import React from "react";
import classes from "./Sidebar.module.scss";
import { Link } from "react-router-dom";
import dashboardIcon from "../../assets/sidebar/dashboard.png";
import logoutIcon from "../../assets/sidebar/logout.png";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem("username") || "N/A";

  const logout = async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/logout`, {}, { headers });
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('username');
      localStorage.removeItem('userType');
      localStorage.removeItem('email');
  

      toast.success("Logged out");
  
     
      navigate('/login');
    } catch (error) {
      console.error(error.message);
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      localStorage.removeItem('username');
      localStorage.removeItem('userType');
      localStorage.removeItem('email');
  
      toast.error("Something went wrong");
    }
  };
  
  return (
    <div className={classes.sidebar}>
      <div className={classes.head}>
        <div className={classes.avatar}>
          <p>{user.toUpperCase()}</p>
        </div>
        <div className={classes.adminName}>
          <p>Welcome</p>
          <p>
            {user.toUpperCase()}
          </p>
        </div>
      </div>
     
      <div className={classes.sidebutton}>
        <Link to="/">
          <div className={classes.sidebar__option}>
            <img src={dashboardIcon} alt="dashboard icon" /> Dashboard
          </div>
        </Link>
      </div>

      <div className={classes.sidebutton}>
        <Link to="/users">
          <div className={classes.sidebar__option}>
            <img src={dashboardIcon} alt="dashboard icon" /> Users
          </div>
        </Link>
      </div>

      <div className={classes.sidebutton}>
        <Link to="/offers">
          <div className={classes.sidebar__option}>
            <img src={dashboardIcon} alt="dashboard icon" /> Offers
          </div>
        </Link>
      </div>

      <div className={classes.sidebutton}>
        <Link to="/trainingvideos">
          <div className={classes.sidebar__option}>
            <img src={dashboardIcon} alt="dashboard icon" /> Training Videos
          </div>
        </Link>
      </div>

      <div className={classes.sidebutton}>
          <div className={classes.sidebar__option}
            onClick={()=>{ logout() }}
          >
            <img src={logoutIcon} alt="all users icon" /> Logout
          </div>
      </div>


     
    </div>
  );
};

export default Sidebar;
