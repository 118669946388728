import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import CommonTable from '../../components/commonTable';
import moment from 'moment';
import { USER_TYPE, USER_TYP_NAME_TO_ID } from '../../common/constants';
import EditUserDetails from '../../components/editUserDetails';
import './users.scss';

const Users = () => {
  const [ users, setUsers ] = useState([]);
  const [ editUserData, setEditUserData] = useState({});
  const [ isEditModalOpen, setEditModalOpen ] = useState(false);
  const [ search, setSearch ] = useState('');
  const [ trainingProgress, setTrainingProgress ] = useState([]);
  const [ trainingTotal, setTrainingTotal ] = useState({ [USER_TYP_NAME_TO_ID.MANAGER]: 0, [USER_TYP_NAME_TO_ID.CASHIER]: 0 });


  useEffect(()=>{
    getAllUsers();
  }, []);

  const getAllUsers = async()=>{
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/getallusers`, {headers});

      const data = response.data;

      if (data.status) {
        setUsers(data.data.users);
        setTrainingProgress(data.data.trainingProg);
        setTrainingTotal(data.data.trainingCnt);
      }
    } catch (error) {
      console.error('get error', error.response);
      toast.error(error.response.data.message);
    }
  }

  const getTrainingProg = (user)=>{
    let prog = "-";
    if(user.userType === USER_TYP_NAME_TO_ID.MANAGER || user.userType === USER_TYP_NAME_TO_ID.CASHIER){
      let progress = trainingProgress.find(t => t._id === user.id);
      prog = `${ progress ? progress.count : "0"}/${trainingTotal[user.userType]}`
    }
    return prog;
  }

  const columns = [
    {
      Header: "Created At",
      Cell: (row) => <div>{moment(row.row.original.createdAt).format('MM-DD-YYYY HH:mm')}</div>,
    },
    {
      Header: "Name",
      Cell: (row) => <div>{`${row.row.original.firstName} ${row.row.original.lastName}`}</div>,
    },
    {
      Header: "Email",
      accessor: 'email'
    },
    {
      Header: "Mobile",
      accessor: 'mobile',
      Cell: (row)=> <>{row.row.original.mobile || "-"}</>
    },
    {
      Header: "Is Verified",
      Cell: (row)=> <div>{row.row.original.isVerified ? "Yes" : "No" }</div>
    },
    {
      Header: "User Type",
      Cell: (row)=> <div>{ USER_TYPE[row.row.original.userType]  }</div>
    },
    {
      Header: "Member Id",
      accessor: 'memId',
      Cell: (row)=> <>{row.row.original.memId || "-"}</>
    },
    {
      Header: "Training Progress",
      Cell: (row)=> <>{ getTrainingProg(row.row.original) }</>
    },
    {
      Header: "Active",
      Cell: (row)=> <div>{row.row.original.isActive ? "Yes" : "No" }</div>
    },
    {
      Header: "Action",
      Cell: (row) => 
      <div>
        <button onClick={() => handleEdit(row.row.original)}>View/Edit</button>
      </div>
    }
  ];

  const handleEdit = (row = {} )=>{
    setEditUserData(row);
    setEditModalOpen(true);
  }

  const handleSearch = async ()=>{
    try{
      if(!search){
        return toast.error('Type something to search');
      }
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/searchusers`, {search}, {headers});

      const data = response.data;

      if (data.status) {
        setUsers(data.data.users);
        setTrainingProgress(data.data.trainingProg);
        setTrainingTotal(data.data.trainingCnt);
      }
    }catch(err){
      toast.error(err.response.data.message)
    }
  }


  return (
    <div className="app">
      <>
      <div style={{fontSize: 18, fontWeight: 'bold'}}>Users</div>
      <input type='text' placeholder='Name/Member Id/Email/Mobile' value={search} onChange={(e)=>setSearch(e.target.value)} ></input>
      <button onClick={()=>{handleSearch()}}>Search</button>
      <button onClick={()=>{ setSearch(''); getAllUsers(); }}>Reset</button>
      {
        users && users.length ?
         <CommonTable columns={columns} data={users}/>
         : null
      }
      {
        isEditModalOpen && 
          <EditUserDetails key={editUserData._id} user={editUserData} isOpen={isEditModalOpen} onClose={()=>{ setEditModalOpen(false); setEditUserData({}); }}  getAllUsers={getAllUsers} />
      }
      </>
    </div>
  );
};

export default Users;
