import React, { useEffect, useState } from 'react';
import './editTrainingVideo.scss';
import { toast } from 'react-toastify';
import axios from 'axios';
import { TRAINING_FOR } from '../../common/constants';
import Select from 'react-select';


const EditTrainingVideoModal = ({ isOpen, onClose, training, getTraining }) => {
  
  const [title, setTitle] = useState(training.title);
  const [description, setdescription] = useState(training.description);
  const [videoUrl, setVideoUrl] = useState(training.videoUrl);
  const [isActive, setIsActive] = useState(training.isActive);
  const [ trainingFor, setTrainingFor ] = useState(createDropdownArr(training.trainingFor));
  const [ videoId, setVideoId ] = useState(training.videoId);

  function createDropdownArr(data){
    return data.map(d => TRAINING_FOR.find(t => t.value == d));
  }

  useEffect(()=>{

  },[]);

  const handleSubmit = async(e) => {
    // Process the updated offer data here
   
    try {
      e.preventDefault();
      if(!trainingFor.length){
        toast.error('Please select training for');
        return;
      }
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
      let obj = {
        trainingId: training._id,
        title,
        description,
        videoUrl,
        videoId,
        trainingFor: trainingFor.map(t => t.value),
        isActive
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/edittraining`, obj, {headers});
      toast.success("Training details updated");
      getTraining();
      onClose();
    } catch (error) {
      console.error('get error', error.response);
      toast.error(error.response.data.message);
    }
  };

  if (!isOpen) return null;

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="edit-offer-modal-overlay" onClick={handleOutsideClick}>
      <div className="edit-offer-modal">
        <button className="modal-close-btn" onClick={onClose}>&times;</button>
        <h2>Edit Training Video</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Title:
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
          </label>
          <label>
            Description:
            <textarea value={description} rows={7} onChange={(e) => setdescription(e.target.value)} />
          </label>
          <label>
            Training For<span style={{color: 'red' }}>*</span>
            <Select 
              options={TRAINING_FOR} 
              defaultValue={trainingFor}
              onChange={setTrainingFor}
              isMulti={true}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </label>
          <label>
            Video URL:
            <input type="url" value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} />
          </label>
          <label> Video Id <span style={{color: 'red' }}>*</span> 
            <input type="text" value={videoId} onChange={(e) => { setVideoId(e.target.value) } } required />
              Note: Verify video Id. If videoId seems incorrect, use the following <a target='_blank' href='https://commentpicker.com/youtube-video-id.php'>link</a>  to get correct video Id
          </label>
          
          <label>
            Active:
            <input type="checkbox" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
          </label>
          <button type="submit">Update</button>
        </form>
      </div>
    </div>
  );
};

export default EditTrainingVideoModal;
