import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import './addTrainingVideo.scss';
import { TRAINING_FOR } from '../../common/constants';
import Select from 'react-select';

const AddTrainingVideoModal = ({ isOpen, onClose, getTraining }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [ trainingFor, setTrainingFor ] = useState([]);
  const [videoId, setVideoId] = useState('');
  
  
  const resetModal = () => {
    setTitle('');
    setDescription('');
    setVideoUrl('');
    setTrainingFor([]);
    setVideoId('');
  };

  const verifyVideoId = (url)=>{
    let regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    if(url){
      var vid = regex.exec(url)[3]; 
      if(vid.length){
        setVideoId(vid);
      }
    }
  }

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({ title, description, videoUrl });
    if(!trainingFor.length){
      toast.error('Please select training for');
      return;
    }
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      };
      let obj = {
        title, 
        description, 
        videoUrl,
        videoId,
        trainingFor: trainingFor.map(t => t.value)
      };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/admin/addtrainingvideo`, obj, {headers});
      const data = response.data;
      console.log(data);
      if (data.status) {
        getTraining();
        closeModal(); // Close modal after submit
      }
    } catch (error) {
      console.error('add training video error', error.response);
      toast.error(error.response.data.message);
    }
  };

  if (!isOpen) return null;

  const closeModal = ()=>{
    resetModal();
    onClose();
  }

  return (
    <div className="add-offer-modal-overlay" onClick={handleOutsideClick}>
      <div className="add-offer-modal">
        <div className='heading'>
          <h2 style={{ alignItems: 'flex-start' }}>Add New Training Video</h2>
          <button className="modal-close-btn" onClick={closeModal}>&times;</button>
        </div>
        <form onSubmit={handleSubmit}>
          <label>
            Title<span style={{color: 'red' }}>*</span>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
          </label>
          <label>
            Description<span style={{color: 'red' }}>*</span>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows="5"
              required
            ></textarea>
          </label>
          <label>
            Training For<span style={{color: 'red' }}>*</span>
            <Select 
              options={TRAINING_FOR} 
              value={trainingFor}
              onChange={setTrainingFor}
              isMulti={true}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </label>
          <label>
            Video Url<span style={{color: 'red' }}>*</span>
            <input type="text" value={videoUrl} onChange={(e) => { setVideoUrl(e.target.value); verifyVideoId(e.target.value); } } required />
          </label>
          <label> Video Id <span style={{color: 'red' }}>*</span> 
            <input type="text" value={videoId} onChange={(e) => { setVideoId(e.target.value) } } required />
              Note: Verify video id. If video id seems incorrect, use the following <a target='_blank' href='https://commentpicker.com/youtube-video-id.php'>link</a>  to get correct video id
          </label>
          <label className='instruction-label'>
            Instructions to upload video on YouTube: <br/>
            Step 1 - Open Youtube and click on Create button -> Upload Video on right side of screen. <br/>
            Step 2 - Select file -> Select "No, its not made for kids" -> Click "Next" -> Click "Next" -> Click "Next"  <br/>
            Step 3 - In Visibility, select "Unlisted" and Click "Save".  <br/>
            Step 4 - Copy the video link shown and paste it in the video Url textbox<br/>
          </label>
          
          <button type="submit">Add</button>
        </form>
      </div>
    </div>
  );
};

export default AddTrainingVideoModal;
