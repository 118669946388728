
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Login from "./pages/login";
import PanelLayout from "./components/layout";
import Dashboard from './pages/dashboard';
import Offers from './pages/offers';
import Users from './pages/users';
import TrainingVideos from './pages/trainingVideos';
import './App.css';

function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path='/login' element = {<Login />} />
        <Route path="/" element={<PanelLayout />}>
          <Route index element={<Dashboard />} />
          <Route path='/offers' element={<Offers/>}/>
          <Route path='/users' element={<Users/>}/>
          <Route path='/trainingvideos' element={<TrainingVideos/>}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
